
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzStepper from "@/components/Stepper.vue";
  import LzModal from "@/components/Modal.vue";
  import Products from "../api";
  import { namespace } from "vuex-class";
  import { Order, Product } from "../api/types";
  import DesignModal from "../components/DesignModal.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import SectionToggle from "@/components/SectionToggle.vue";

  const auth = namespace("auth");

  @Component({
    components: {
      LzButton,
      LzTable,
      LzStepper,
      LzModal,
      DesignModal,
      LzConfirm,
      SectionToggle
    }
  })
  export default class Read extends Vue {
    @auth.State("id")
    public memberId!: string;

    @auth.State("url")
    public url!: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    active = "products";
    products: Product[] = [];

    listFields = [
      { id: "title", label: this.$t("shop.read.productsTable.name") },
      {
        id: "discount",
        label: this.$t("shop.read.productsTable.discount")
      },
      { id: "price", label: this.$t("shop.read.productsTable.price") },
      {
        id: "status",
        label: this.$t("shop.read.productsTable.status.label")
      },
      { id: "actions", label: this.$t("shop.read.productsTable.actions") }
    ];

    orders: Order[] = [];

    ordersFields = [
      { id: "name", label: this.$t("shop.read.ordersTable.name") },
      { id: "code", label: this.$t("shop.read.ordersTable.trackingCode") },
      { id: "products", label: this.$t("shop.read.ordersTable.products") },
      { id: "total", label: this.$t("shop.read.ordersTable.total") },
      { id: "status", label: this.$t("shop.read.ordersTable.status") }
   /*    { id: "actions", label: this.$t("shop.read.ordersTable.actions") } */
    ];

    orderFields = [
      { id: "name", label: this.$t("shop.read.modalOrder.table.name") },
      {
        id: "products",
        label: this.$t("shop.read.modalOrder.table.products")
      },
      { id: "price", label: this.$t("shop.read.modalOrder.table.price") },
      { id: "total", label: this.$t("shop.read.modalOrder.table.total") }
    ];

    modalOrderOptions = {
      pending: this.$t("shop.read.modalOrder.status.options.pending"),
      accepted: this.$t("shop.read.modalOrder.status.options.accepted"),
      sent: this.$t("shop.read.modalOrder.status.options.sent"),
      delivered: this.$t("shop.read.modalOrder.status.options.delivered")
    };

    deleteModal = false;
    itemToDelete: Product;

    mounted() {
      this.getProducts();
      this.getOrders();
    }

    getProducts() {
      Products.getAllByMemberId(this.memberId).then(res => {
        this.products = res;
      });
    }

    getOrders() {
      Products.getAllOrdersMemberId(this.memberId).then(res => {
        this.orders = res;
      });
    }

    async deleteItem() {
      if (!this.itemToDelete) return;

      await Products.delete(this.itemToDelete.id)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("shop.create.notifications.productRemoved")
          });
          this.$router.push({ name: "shopRead" });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });

      await Products.getAllByMemberId(this.memberId).then(res => {
        this.products = res;
        this.deleteModal = false;
      });
    }

    toggleProducts() {
      // console.log("Toggling products");
      return;
    }
  }
